import React, { FC, PropsWithChildren, useContext, useMemo, useState } from 'react';
import Style from './issueMark.module.scss';
import { useContextSelector } from 'use-context-selector';
import { IssuesContext } from '../provider/issues.context';
import { IssueCreationType } from '../form/issueForm.component';
import { Tooltip } from 'uikit/tooltip/tooltip.component';
import { FeatureFlags } from 'src/application/constants/feature-flags';
import { IssueDto, IssuesStateEnum } from 'src/services/api/api-client';
import { AppButton } from 'uikit/buttons';
import { ReactComponent as PlusIcon } from 'assets/img/common/plus_16.svg';
import clsx from 'clsx';
import { useHasPermissions } from 'src/helpers/auth/auth-helper';
import { Permissions } from 'src/services/api/api-client';
import { useScopedTranslation } from 'src/application/localisation/useScopedTranslation';
import { patientFilterParams } from 'src/components/patientTable/patientFilters.helpers';
import { useQueryParams, NumberParam } from 'use-query-params';
import { useStudy } from 'src/helpers/hooks/useStudy';
import { PatientCardContext } from '../../expandedRow/PatientCardContext';
import { LocalizedResourceDictionaryKeys } from '../../../application/localisation/i18next';

type IssueContext = Partial<Omit<IssueCreationType, 'deadlineAt' | 'recommendedAction' | 'files' | 'description'>>;

type IssueMarkType = {
  issueContext: IssueContext;
  disabled?: boolean;
  containerClassName?: string;
  countDescendants?: boolean;
  ignoreFieldsForCount?: (keyof IssueContext)[];
  position?: {
    top?: number;
    right?: number;
    left?: number;
    bottom?: number;
  };
};

export const IssueMark: FC<PropsWithChildren<IssueMarkType>> = ({
  children,
  issueContext,
  containerClassName,
  countDescendants = true,
  ignoreFieldsForCount = [],
  position = {
    top: 0,
    right: 0,
  },
  disabled,
}) => {
  const { t } = useScopedTranslation('Issues.Mark');
  const { t: topicLocalizer } = useScopedTranslation('Issues.Topic');
  const { createIssue, openIssueView, isIssuesVisible } = useContextSelector(IssuesContext, (x) => x)!;
  const { hasMonitoring, closingApprovedAt } = useStudy() ?? {};
  const relatedIssues = useRelatedIssues(issueContext, countDescendants, ignoreFieldsForCount);
  const isPatientIssueCreationDisabled = usePatientStudyFinished(issueContext);
  const { hasPermission } = useHasPermissions();
  const [buttonHovered, setButtonHovered] = useState(false);
  const [containerHovered, setContainerHovered] = useState(false);
  const [patientQueryParams] = useQueryParams({
    ...patientFilterParams(),
    issueId: NumberParam,
  });

  const hasSelectedIssue = useMemo(
    () => relatedIssues?.some((x) => x.id === patientQueryParams.issueId),
    [patientQueryParams.issueId, relatedIssues],
  );

  const tooltipContent = useMemo(() => {
    return (
      <div className={Style.tooltipContent}>
        {relatedIssues?.map((x) => (
          <AppButton
            key={x.id}
            variant={'icon-link'}
            colorSchema={'primary'}
            text={`${t('RelatedIssue', { id: x.id })}, ${topicLocalizer(x.topic as LocalizedResourceDictionaryKeys)}`}
            onClick={() => openIssueView?.(x.id)}
            textClassName={Style.openButton}
          />
        ))}
      </div>
    );
  }, [openIssueView, relatedIssues, t, topicLocalizer]);

  const mark = useMemo(() => {
    if (hasPermission(Permissions.IssueCreate) && !closingApprovedAt)
      return (
        <button
          className={Style.button}
          onClick={(e) => {
            e.stopPropagation();
            createIssue?.({ ...issueContext });
          }}
          data-has-related-issues={!!relatedIssues?.length}
          data-opacity={
            !!patientQueryParams.issueId && !hasSelectedIssue && !(containerHovered && !relatedIssues?.length)
          }
          onMouseEnter={() => setButtonHovered(true)}
          onMouseLeave={() => setButtonHovered(false)}
        >
          {buttonHovered ? <PlusIcon /> : relatedIssues?.length || <PlusIcon />}
        </button>
      );

    if (!relatedIssues?.length) return null;

    return (
      <div className={clsx(Style.mark, { [Style.opacity]: !!patientQueryParams.issueId && !hasSelectedIssue })}>
        {relatedIssues?.length}
      </div>
    );
  }, [
    hasPermission,
    closingApprovedAt,
    relatedIssues?.length,
    patientQueryParams.issueId,
    hasSelectedIssue,
    containerHovered,
    buttonHovered,
    createIssue,
    issueContext,
  ]);

  if (
    disabled ||
    isPatientIssueCreationDisabled ||
    (!relatedIssues?.length && !hasPermission(Permissions.IssueCreate)) ||
    !isIssuesVisible ||
    !hasMonitoring ||
    !FeatureFlags.isIssueTargetEnabled()
  )
    return <>{children}</>;

  return (
    <div
      className={clsx(Style.issueMarkContainer, containerClassName)}
      onMouseEnter={() => setContainerHovered(true)}
      onMouseLeave={() => setContainerHovered(false)}
    >
      {(containerHovered || !!relatedIssues?.length) && (
        <div className={Style.content} style={{ ...position }}>
          <Tooltip
            disabled={!relatedIssues?.length}
            tooltipContent={tooltipContent}
            text={''}
            calloutContainerStyles={Style.callout}
          >
            {mark}
          </Tooltip>
        </div>
      )}
      {children}
    </div>
  );
};

const fieldsForFilter: (keyof IssueCreationType)[] = [
  'subject',
  'linkedPatientUniqId',
  'topic',
  'topicAdditional',
  'fieldId',
  'fieldDescription',
  'resultId',
];

const useRelatedIssues = (
  issueContext: IssueContext,
  countDescendants: boolean,
  ignoreFields: string[],
): IssueDto[] | undefined => {
  const issues = useContextSelector(IssuesContext, (x) => x.loadedIssues)!;

  const contextKeys = useMemo(
    () => Object.keys(issueContext).filter((x) => !ignoreFields.includes(x)),
    [ignoreFields, issueContext],
  );

  const relatedIssues = useMemo(
    () =>
      issues
        .filter((issue) =>
          contextKeys.every(
            (key) => issue[key] === issueContext[key] || issue[key]?.toString().split(', ').includes(issueContext[key]),
          ),
        )
        .filter((x) => x.state !== IssuesStateEnum.Closed),
    [contextKeys, issueContext, issues],
  );

  if (countDescendants) {
    return relatedIssues;
  }

  const otherKeys = fieldsForFilter.filter((k) => !contextKeys.includes(k));
  return relatedIssues?.filter((issue) => otherKeys.every((key) => (issue[key] || undefined) === issueContext[key]));
};

function usePatientStudyFinished(issueContext: IssueContext): boolean | undefined {
  const patientCardContext = useContext(PatientCardContext);
  const isPatientFinished = patientCardContext?.patient?.isStudyFinished;
  const { linkedPatientUniqId } = issueContext;

  if (!linkedPatientUniqId || isPatientFinished === undefined) {
    return undefined;
  }

  return isPatientFinished;
}
