import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { DailyCheckCalendarProps, SortedAnswers } from './dailyCheckCalendar';
import Style from './dailyCheckCalendar.module.css';
import { TypographyStyles } from '../../styles';
import groupBy from 'lodash.groupby';
import { addMonths, isSameMonth, startOfDay } from 'date-fns';
import { MonthComponent } from './Month.component';
import { formatDateRange } from 'src/helpers/date-helpers';
import { DailycheckPaginator } from './dailyCheckPaginator.component';
import { eachMonthOfInterval } from 'date-fns/esm';
import { AppModalContainer } from '../../components/uikit/modal/modal.component';
import { Loading } from 'uikit/suspense/Loading';
import { useGetAnswersStatisticQuery } from 'src/services/api/api-client/SurveyQuery';

const ITEM_PER_PAGE = 6;

export const DailyCheckCalendar = (props: DailyCheckCalendarProps) => {
  const { t } = useTranslation();
  const { isInitialLoading, data: dailyStats } = useGetAnswersStatisticQuery(props.patientId, 'Atemnot', {
    suspense: false,
  });

  const [pageCounter, setPageCounter] = useState(1);
  const [numberOfMonths, setNumberOfMonths] = useState(0);

  // This effect sets the first page to the current month
  useEffect(() => {
    if (!dailyStats) return;

    const { studyStartedAt, studyFinishedAt } = dailyStats;
    const months = eachMonthOfInterval({ start: studyStartedAt, end: studyFinishedAt });
    const index = months.findIndex((x) => isSameMonth(x, Date.now()));
    if (index === -1) return;

    const initialPage = Math.floor(index / ITEM_PER_PAGE) + 1;
    setPageCounter(initialPage);
  }, [dailyStats]);

  const header = (
    <p className={clsx(Style.modalHeader, TypographyStyles.plainText14)}>
      {t('Dashboard.PatientAccordion.SurveySection.StatisticsModal.Header', {
        period:
          dailyStats && dailyStats.studyStartedAt
            ? formatDateRange(dailyStats.studyStartedAt, dailyStats.studyFinishedAt)
            : '',
      })}
    </p>
  );

  const handlePagination = (pageNumber: number) => setPageCounter(pageNumber);

  const content = useMemo(() => {
    if (!dailyStats) {
      return null;
    }

    const answersGroupedByDays: SortedAnswers = groupBy(dailyStats.answers, (answer) =>
      startOfDay(answer.answerDate).toString(),
    );
    const { studyStartedAt, studyFinishedAt } = dailyStats;

    const months: JSX.Element[] = [];
    const numberOfMonthsToRender = eachMonthOfInterval({ start: studyStartedAt, end: studyFinishedAt }).length;
    const monthsPerPage = Math.min(ITEM_PER_PAGE, numberOfMonthsToRender);
    const numberOfPages = Math.ceil(numberOfMonthsToRender / monthsPerPage);
    const maxNumberOfMonths = numberOfPages === pageCounter ? numberOfMonthsToRender : pageCounter * monthsPerPage;
    for (let i = (pageCounter - 1) * monthsPerPage; i < maxNumberOfMonths; i++) {
      const consideredDate = addMonths(studyStartedAt, i);
      const newMonth = (
        <MonthComponent
          answers={answersGroupedByDays}
          activeDate={consideredDate}
          key={consideredDate.toString()}
          maxAnsweredDate={dailyStats.maxAnswerDate ?? new Date(Date.now())}
          minAnsweredDate={dailyStats.minAnswerDate ?? studyStartedAt}
        />
      );
      months.push(newMonth);
    }
    setNumberOfMonths(months.length);
    return (
      <div>
        <div
          className={clsx({
            [Style.monthsContainer]: true,
            [Style.monthsContainerWithLoader]: isInitialLoading,
            [Style.oneMonthContainer]: numberOfMonths === 1,
          })}
        >
          {months}
        </div>
        <DailycheckPaginator
          itemsPerPage={ITEM_PER_PAGE}
          totalNumberOfItems={eachMonthOfInterval({ start: studyStartedAt, end: studyFinishedAt }).length}
          handlePagination={handlePagination}
          counterValue={pageCounter}
        />
      </div>
    );
  }, [dailyStats, pageCounter, isInitialLoading, numberOfMonths]);

  return (
    <AppModalContainer
      title={t('Dashboard.PatientAccordion.SurveySection.StatisticsModal.DailyCheck.Title')}
      header={header}
      visible={props.isVisible}
      onHide={props.onClose}
      isClickOutside={true}
      bodyClassName={Style.modalBody}
    >
      <Loading loading={isInitialLoading} containerClassName={Style.loading} renderChildren={false}>
        {dailyStats ? content : <p>{t('Common_NoData')}</p>}
      </Loading>
    </AppModalContainer>
  );
};
